import { FC, MouseEvent, useCallback } from 'react';
import { EElementSize } from '../../types';
import { AlertCircleIcon, CloseIcon } from '../icons';
import {
  InputWrapperStateStyled,
  InputWrapperStyled,
} from './input-wrapper.styled';
import { EState, IInputWrapperProps } from './input-wrapper.types';

export const InputWrapper: FC<IInputWrapperProps> = ({
  icon,
  children,
  actionButtons,
  state = EState.NONE,
  isClearable,
  disabled,
  cursor,
  width,
  $isStatic,
  onClear = () => {},
  $size = EElementSize.L,
}) => {
  const clearHandler = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      onClear();
    },
    [onClear],
  );

  return (
    <InputWrapperStyled
      $size={$size}
      disabled={disabled}
      $cursor={cursor}
      $width={width}
      $state={state}
      $isStatic={$isStatic}
    >
      {!!icon && <div className="input-wrapper-icon">{icon}</div>}
      <div className="inner-content">{children}</div>
      {state && (
        <InputWrapperStateStyled>
          <AlertCircleIcon className="status-icon" />
        </InputWrapperStateStyled>
      )}
      {isClearable && (
        <div className="input-wrapper-action">
          <CloseIcon
            className="input-wrapper-action-clear"
            onClick={clearHandler}
          />
        </div>
      )}
      {!!actionButtons && (
        <div className="input-wrapper-action">{actionButtons}</div>
      )}
    </InputWrapperStyled>
  );
};
